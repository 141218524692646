.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.card--max-height{
    height: 100%;
}

.card>hr {
    margin-right: 0;
    margin-left: 0;
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit;
}

.card>.list-group:first-child {
    border-top-width: 0;
}

.card>.list-group:last-child {
    border-bottom-width: 0;
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
    border-top: 0;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link+.card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
    flex-shrink: 0;
    width: 100%;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;

        &.no-gutters {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .card-deck .card {
        flex: 1 0 0%;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group>.card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap;
    }

    .card-group>.card {
        flex:inherit;
        margin-bottom: 0;
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}


.card-deck.slick-slider {
    margin-left: 0;
    margin-right: 0;
}

.card-deck.slick-slider .card {
    margin-left: 0;
    margin-right: 0;
}

.card {
    transition: all .33s;
    /*&.highlight:hover {
          box-shadow: 0 0.4375rem 1rem -0.3125rem rgba(0,0,0,.2);
          transform: translateY(-0.25rem);
      }*/
}

.card h4 {
    font-size: 1.25rem;
    text-transform: uppercase;
}

/* control image height */
.card-img-top-250 {
    overflow: hidden;
    max-height: 150px;
}

@media (min-width: 768px) {
    .card-img-top-250 {
        max-height: 150px;
        min-height: 150px;
    }
}

@media (min-width: 992px) {
    .card-img-top-250 {
        max-height: 250px;
        min-height: 250px;
    }
}

.card-body {
    padding: .5rem;
}

@media (min-width: 992px) {
    .card-body {
        padding: .5rem 1.5rem;
    }
}

.card-title {
    font-size: 1.15rem;
}

.card-title.large {
    font-size: 1.75rem;
}

.card-title a {
    color: #1a1a25;
}

.card-footer {
    background-color: transparent;
    border-top: 0;
    padding: .5rem 0 1.5rem;
}

.advice-container .card-footer {
    background-color: transparent;
    border-top: 0;
    padding: .5rem 0 1.5rem;
}

@media (min-width: 992px) {
    .card-title {
        font-size: 1.3rem;
    }

    .card-title.large {
        font-size: 2rem;
    }
}

.card-group>.card {
    flex: inherit;
}

@media (max-width: 767.98px) {
    .card-group>.card:last-child {
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {

    /*.card-group > .card {
          flex: inherit;
      }*/
    .card-columns .card {
        margin-bottom: 1.25rem;
    }

    .card-columns .card .card-body {
        padding: 1.5rem;
    }

    .events-carousel .slick-slide,
    .news-carousel .slick-slide {
        border: 1px solid rgba(0, 0, 0, 0.125);
    }

    .events-carousel .card,
    .news-carousel .card {
        border: 0;
    }

    .events-carousel .card-body,
    .news-carousel .card-body {
        padding: .25rem 1rem;
    }
}

@media (min-width: 992px) {
    .card-body {
        padding: .25rem 1.5rem;
    }

    .news-carousel .card,
    .events-carousel .card {
        overflow: hidden;
        text-overflow: ellipsis;
        height: 495px;
        max-height: 495px;
        min-height: 495px !important;
    }

    .news-carousel .card .card-img,
    .events-carousel .card .card-img {
        max-height: 250px;
        overflow: hidden;
        position: relative;
    }

    .news-carousel .card .card-body,
    .events-carousel .card .card-body {
        padding: 2rem;
    }

    .news-carousel .card .card-body .card-text,
    .events-carousel .card .card-body .card-text {
        overflow: hidden;
        position: relative;
        max-height: 150px;
        margin-right: -1em;
        padding-bottom: 0.9375rem;
        padding-right: 1em;
    }

    .events-carousel .card {
        height: 560px;
        max-height: 560px;
        min-height: 560px !important;
    }
}

.team-card {
    border: 0;
    text-align: center;
}

.team-card img {
    max-width: min-content;
    margin: auto;
}

.accordion .card {
    border: 0;
}
